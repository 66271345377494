import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../layout'
import Section from '../layout/section'
import { faBullseye, faGem, faHandshake, faLightbulb, faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './about_us.css'
import IconWhatsapp from '../components/whatsicon'

const AboutUs = () => {
  return (
    <Layout>
      <Helmet>
        <title>Motorbooks - Sobre a Empresa</title>
      </Helmet>
      
      <IconWhatsapp />
      
      <Section id='about' isDark>
        <h1>A Empresa</h1>
        <p className='blue-text'>
          Somos uma empresa de consultoria e assessoria para o
          segmento de retíficas e oficinas mecânicas, que tem como objetivo analisar de
          forma precisa sua empresa evidenciando possíveis
          problemas e oferecendo a solução.
        </p>
        <p>
          De forma técnica, ágil e precisa, nosso intuito é
          potencializar o seu negócio para que alcance o resultado
          esperado com a garantia de produtos e serviços por nós
          disponibilizados conforme sua demanda.
        </p>
        <p className='blue-text'>
          Então, com a premissa de união do setor....
        </p>
        <p>
          Criamos em 2021, a MotorBooks!
        </p>
        <p className='blue-text'>
          Em meio a uma crise mundial e indo totalmente ao
          contrário do que a economia ditava como regra, o
          fechamento de empresas, iniciamos nossas atividades com
          o objetivo de manter a integridade e estruturação do setor.
        </p>
        <p>
          Atendendo empresas que constantemente
          demandavam de uma garantia pelo serviço prestado e de
          uma gestão de alta qualidade, nossa empresa foi
          minuciosamente desenhada e criada de acordo com a
          necessidade que você retificador e mecânico, tanto procurou.
        </p>
        <p className='blue-text'>
          Com intuito de assessorar e prestar uma consultoria de
          qualidade para as retíficas e oficinas do Brasil, com um sistema
          completamente integrado e elaborado de acordo com sua
          necessidade, montamos um quadro colaborativo de
          engenheiro, administrativo e gestão altamente capacitado,
          e o melhor, exclusivamente parar atendê-los
        </p>
      </Section>
      <Section id='declaration'>
        <div>
          <FontAwesomeIcon icon={faBullseye} />
          <h1>Missão</h1>
          <p>
          Atender as necessidades do mercado com soluções
          eficientes, com o objetivo de melhorar a rentabilidade de
          nossos consumidores, trazendo benefícios concretos e
          produtos que toda empresa tenha orgulho em possuir,
          criando valor e fazendo a diferença no segmento.
          </p>
        </div>
        <div>
          <FontAwesomeIcon icon={faLightbulb} />
          <h1>Visão</h1>
          <p>
          Ser reconhecida como a melhor empresa do Brasil no
          fornecimento de informações técnicas dos maiores
          fabricantes de motores, prover soluções eficientes e
          completas para retíficas e ser a número um em oferecer
          produtos que asseguram o serviço de todo retificador e
          mecânico.
          </p>
        </div>
        <div>
          <FontAwesomeIcon icon={faGem} />
          <h1>Valores</h1>
          <ul>
            <li>Ética e transparência nas informações.</li>
            <li>Trabalho em equipe.</li>
            <li>Flexibilidade.</li>
            <li>Atenção aos detalhes.</li>
            <li>Confiança, respeito e integridade.</li>
          </ul>
        </div>
      </Section>
      <Section id='edges' isDark>
        <h1>Nossos Diferenciais</h1>
        <div>
          <FontAwesomeIcon icon={faLightbulb} />
          <h1>Inovação</h1>
          <p>
          A Motorbooks inova de forma objetiva e precisa, dispondo
          de um time técnico de vasto conhecimento de mercado,
          utilizando de alta tecnologia como solução de problemas
          do cotidiano de todo retificador.
          </p>
        </div>
        <div>
          <FontAwesomeIcon icon={faHandshake} />
          <h1>Compromisso</h1>
          <p>
          Somos comprometidos com nossos clientes, e visamos
          acima de tudo a excelência nos serviços e produtos
          oferecidos, resultando em cumprimento de prazos e a
          satisfação das retíficas e mecânicas em geral.
          Seguir normas, cumprir a legislação e manter a integridade
          do setor são nossas premissas.
          </p>
        </div>
        <div>
          <FontAwesomeIcon icon={faTachometerAlt} />
          <h1>Eficiência</h1>
          <p>
          Resolver necessidades complexas do segmento de retíficas
          de forma simples, utilizando da asseguridade de nossos
          produtos e disponibilizar nossa equipe técnica sempre que
          precisar, dividindo conhecimento e trazendo as novidades
          do mercado, será a solução para crescermos juntos.
          </p>
        </div>
      </Section>
    </Layout>
  )
}

export default AboutUs
